<template>
  <div>
    <div class="box blue-box mb-5">
      <div class="mb-3">
        <label for="input-customer-name">訂車人</label>
        <b-form-input id="input-customer-name" v-model="customer.name">
        </b-form-input>
      </div>

      <label for="input-customer-phone">訂車人電話</label>
      <div class="mb-3">
        <the-mask
          v-model.trim="customer.phone"
          :class="{
            'form-control': true,
          }"
          :masked="true"
          :mask="['(###) ###-####', '(##) #### #######', '(###) #### ####']"
        />
      </div>
      <div
        class="mt-3"
        v-for="(p, p_index) in customer.otherPhones"
        :key="`customer-other-phone-${p_index}`"
      >
        <b-input-group>
          <the-mask
            v-model="customer.otherPhones[p_index]"
            :class="{
              'form-control': true,
            }"
            :masked="true"
            :mask="['(###) ###-####']"
          />

          <b-input-group-append>
            <b-dropdown right variant="light" no-caret size="sm">
              <template #button-content>
                <template
                  v-if="
                    customer.otherPhoneDescriptions &&
                      customer.otherPhoneDescriptions[p_index]
                  "
                >
                  {{ customer.otherPhoneDescriptions[p_index] }}
                </template>
                <template v-else>
                  <b-icon-justify />
                </template>
              </template>
              <b-dropdown-form>
                <b-form-input
                  lazy
                  v-model="customer.otherPhoneDescriptions[p_index]"
                  class="phone-description"
                  placeholder="description"
                  size="sm"
                ></b-form-input>
              </b-dropdown-form>
            </b-dropdown>
            <b-button
              class="border-left-0"
              variant="light"
              size="sm"
              @click.prevent="removeOtherPhone(p_index)"
            >
              <b-icon icon="x"></b-icon>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </div>

      <div class="text-center mt-2 mb-4">
        <a class="h6 no-underline" href="#" @click.prevent="addNewOtherPhone">
          Add new phone
          <b-icon icon="plus"></b-icon>
        </a>
      </div>

      <label for="input-customer-home-address">地址</label>

      <b-input-group class="mb-3">
        <b-input-group-prepend>
          <b-input-group-text>
            <b-icon icon="house-fill"></b-icon>
          </b-input-group-text>
        </b-input-group-prepend>
        <b-form-input
          id="input-customer-home-address"
          v-model="customer.homeAddress"
        ></b-form-input>
      </b-input-group>
      <b-input-group class="mb-3">
        <b-input-group-prepend>
          <b-input-group-text>
            <b-icon icon="building"></b-icon>
          </b-input-group-text>
        </b-input-group-prepend>
        <b-form-input
          id="input-customer-work-address"
          v-model="customer.workAddress"
        ></b-form-input>
      </b-input-group>

      <label for="input-customer-remark">備註</label>
      <b-form-input
        id="input-customer-remark"
        class="mb-3"
        v-model="customer.note"
      ></b-form-input>

      <label for="input-customer-businessCode">商家代碼</label>
      <b-form-input
        id="input-customer-businessCode"
        class="mb-3"
        maxlength="5"
        v-model="customer.businessCode"
      ></b-form-input>

      <div class="d-flex">
        <label class="mr-2" for="input-customer-blacklisted">黑名單: </label>
        <b-form-checkbox
          id="input-customer-blacklist"
          class="mb-3"
          v-model="customer.blacklisted"
        ></b-form-checkbox>
      </div>

      <b-row align-v="end">
        <b-col cols="4">
          <b-form-select
            :options="settings.payment_methods"
            v-model="customer.payment_method"
          ></b-form-select>
        </b-col>
        <b-col cols="8" class="text-right">
          <b-button
            size="sm"
            variant="outline-tertiary"
            class="mr-3"
            @click="setCustomer"
            >更新客戶</b-button
          >
          <b-button
            size="sm"
            variant="outline-tertiary"
            class="mr-3"
            @click="$emit('orderButtonClick')"
            >過去記錄</b-button
          >
          <b-button size="sm" variant="danger" @click="deleteCustomer"
            >刪除客戶</b-button
          >
        </b-col>
      </b-row>
    </div>

    <template v-if="!$_.isEmpty(customer.passengers)">
      <div class="box compact-box py-0">
        <b-row class="gx-1 mb-2">
          <b-col cols="4">乘客電話</b-col>
          <b-col cols="4">乘客</b-col>
          <b-col cols="4">備註</b-col>
        </b-row>
      </div>
      <div
        class="box gray-box compact-box mb-2"
        v-for="(p, p_index) in customer.passengers"
        :key="`pass-${p_index}`"
      >
        <b-row class="gx-1" align-v="center">
          <b-col cols="4">
            <the-mask
              v-model="p.phone"
              :class="{
                'form-control': true,
              }"
              :masked="true"
              :mask="['#### ####', '(###) ###-####']"
              :type="tel"
              v-if="customerPassengersEditMode[p_index]"
            />

            <div class="box compact-box" v-else>{{ p.phone || "-" }}</div>
          </b-col>
          <b-col cols="4">
            <b-form-input
              v-if="customerPassengersEditMode[p_index]"
              v-model="p.name"
            />
            <div class="box compact-box" v-else>{{ p.name || "-" }}</div>
          </b-col>
          <b-col cols="2">
            <b-form-input
              v-if="customerPassengersEditMode[p_index]"
              v-model="p.remark"
            />
            <div class="box compact-box" v-else>{{ p.remark || "-" }}</div>
          </b-col>
          <b-col cols="2">
            <b-button
              variant="link"
              @click="enablePassengerEditMode(p_index)"
              size="sm"
              v-if="!customerPassengersEditMode[p_index]"
            >
              <b-icon icon="pencil"></b-icon>
            </b-button>
            <b-button variant="link" @click="updatePassenger" size="sm" v-else>
              <b-icon icon="check"></b-icon>
            </b-button>
            <b-button
              variant="link"
              size="sm"
              @click="removePassenger(p_index)"
            >
              <b-icon icon="trash-fill"></b-icon>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>

    <div class="text-center">
      <a href="#" @click.prevent="addPassenger">添加乘客</a>
    </div>
  </div>
</template>

<script>
import { isArray, cloneDeep } from "lodash";
import { mapState } from "vuex";

import { TheMask } from "vue-the-mask";

export default {
  props: ["customerData"],
  components: { TheMask },
  data() {
    return {
      customerPassengersEditMode: [],
      customer: null,
    };
  },
  watch: {
    customerData: {
      handler(nv) {
        let _customer = cloneDeep(nv);
        if (!_customer.otherPhoneDescriptions) {
          _customer.otherPhoneDescriptions = [];
        }

        this.customer = _customer;
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      settings: (state) => state.settings.settings,
    }),
  },
  methods: {
    addNewOtherPhone() {
      if (!isArray(this.customer.otherPhones))
        this.$set(this.customer, "otherPhones", [""]);
      else this.customer.otherPhones.push("");
    },
    removeOtherPhone(i) {
      this.customer.otherPhones.splice(i, 1);
      this.customer.otherPhoneDescriptions.splice(i, 1);
    },
    enablePassengerEditMode(p_index) {
      this.customerPassengersEditMode[p_index] = !this
        .customerPassengersEditMode[p_index];
      this.$forceUpdate();
    },
    removePassenger(p_index) {
      this.customer.passengers.splice(p_index, 1);
      this.customerPassengersEditMode = [];

      if (this.customer.customerId) this.setCustomer();
    },
    updatePassenger() {
      if (this.customer.customerId) this.setCustomer();
    },
    addPassenger() {
      if (!isArray(this.customer.passengers)) this.customer.passengers = [];

      this.customer.passengers.push({});
      this.customerPassengersEditMode[
        this.customer.passengers.length - 1
      ] = true;

      this.$forceUpdate();
    },
    async setCustomer() {
      const _customer = Object.assign({}, this.customer);
      if (_customer["businessCode"]) {
        _customer["businessCode"] = _customer["businessCode"]?.toUpperCase();

        const codeExist = await this.$store.dispatch("customers/check", {
          filters: {
            businessCode: _customer["businessCode"],
            notCustomerId: _customer["customerId"] || "",
          },
          orderBy: "customerId",
        });

        if (codeExist) {
          this.$notify({
            text: "Code already exist! Please type another one",
            type: "error",
          });
          return;
        }

        if (_customer["businessCode"].length != 5) {
          this.$notify({
            text: "Business code must be 5 characters and cannot be repeated",
            type: "error",
          });
          return;
        }
      }

    _customer["phoneClean"] = _customer.phone.replace(/[^0-9]/gi, '');

      _customer["addresses"] = [
        _customer["homeAddress"] ?? "",
        _customer["workAddress"] ?? "",
      ];
      if (isArray(_customer.otherPhoneDescriptions)) {
        for (
          var d_index = 0;
          d_index < _customer.otherPhoneDescriptions.length;
          d_index++
        ) {
          if (!_customer.otherPhoneDescriptions[d_index])
            _customer.otherPhoneDescriptions[d_index] = "";
        }
      } else {
        _customer.otherPhoneDescriptions = [];
      }
      let setData = { data: _customer };
      if (_customer.customerId) setData.customerId = _customer.customerId;

      const val = await this.$store.dispatch("customers/set", setData);

      if (val?.error) {
        this.$notify({
          text: val.error,
          type: "error",
        });
        return;
      }

      this.customerPassengersEditMode = [];

      this.$notify({
        text: "更新成功",
        type: "success",
      });
    },
    async deleteCustomer() {
      const _customerId = this.customer.customerId;

      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to delete this customer? (It cannot be undone)"
        )
        .then(async (value) => {
          if (value) {
            const val = await this.$store.dispatch(
              "customers/delete",
              this.customer.customerId
            );

            if (val?.error) {
              this.$notify({
                text: val.error,
                type: "error",
              });
              return;
            }

            this.customer = {};

            this.$notify({
              text: "customer removed successfully",
              type: "success",
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.input-group-text {
  background-color: #fff;
}

.crt-phone {
  padding: 0 0 0 5px;
  display: inline-block;
  color: #fff;
  font-size: 1.5rem;
}

.phone-description {
  width: 120px;
}

#input-customer-businessCode {
  text-transform: uppercase;
}
</style>
