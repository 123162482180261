<template>
  <b-row id="customers-row" class="gx-2 flex-nowrap">
    <b-col id="customers-search-col" cols="auto">
      <div class="box full-box">
        <h1 class="box-title">搜尋客戶 / Search</h1>

        <b-form-group
          class="mb-0"
          label-cols="3"
          label="搜索"
          label-for="input-phone-search"
          label-size="lg"
        >
          <div class="row gx-1 align-items-center">
            <div class="col">
              <SearchInput
                key="customer-input-search"
                index="Customers"
                title="name"
                subtitle="otherPhones"
                @change="searchFieldChange"
              />
            </div>
            <div class="col-auto">
              <a @click.prevent="searchPhone" href="#" class="no-underline"
                ><b-icon-search></b-icon-search
              ></a>
            </div>
          </div>
        </b-form-group>

        <b-row class="gx-1">
          <b-col offset="3">
            <b-button size="sm" @click="showNewCustomer">New Customer</b-button>
          </b-col>
        </b-row>

        <hr class="mx-0 my-4" />

        <CustomerForm
          :customerData="newCustomer"
          v-if="$_.isEmpty(customer) && showNewCustomerForm"
        />
        <CustomerForm
          @orderButtonClick="selectCustomer(customer)"
          :customerData="customer"
          v-else-if="!$_.isEmpty(customer)"
        />
      </div>
    </b-col>
    <b-col id="customer-order-col">
      <div class="box full-box">
        <template v-if="showCustomerRecentOrders">
          <b-row>
            <b-col cols="auto">
              <h2 class="box-title">最近記錄 / Recent Order</h2>
            </b-col>
            <b-col cols="auto" class="ml-auto">
              <DateRangeOptions
                v-model="dateRange"
                openPosition="left"
                defaultRange="Date Range"
              />
            </b-col>
          </b-row>

          <b-table
            sticky-header
            head-variant="light"
            :items="orderItems"
            :fields="orderFields"
            :no-border-collapse="false"
            responsive
            :show-empty="true"
            :class="{
              'empty-table': $_.isEmpty(orderItems),
            }"
          >
            <template #empty>
              <div class="box outline-box p-4">
                <p class="text-center m-0 h6">
                  This customer does not have order for
                  {{
                    dateHelpers.dateRangeFormat(
                      dateRange.startDate,
                      dateRange.endDate
                    )
                  }}
                </p>
              </div>
            </template>
            <template #custom-foot>
              <b-tr id="custom-foot">
                <b-th colspan="4"></b-th>
                <b-th>
                  <div style="line-height: 1.2;">
                    總數 <br /><small>(包括其他費用)</small>
                  </div>
                  ${{
                    sumBy(
                      "cost",
                      (o) =>
                        ["confirmed", "waiting"].indexOf(
                          o.status.toLowerCase()
                        ) >= 0
                    ) +
                      sumBy(
                        "receipt",
                        (o) =>
                          ["confirmed", "waiting"].indexOf(
                            o.status.toLowerCase()
                          ) >= 0
                      ) +
                      sumBy(
                        "cost_407",
                        (o) =>
                          ["confirmed", "waiting"].indexOf(
                            o.status.toLowerCase()
                          ) >= 0
                      )
                  }}
                </b-th>
                <b-th valign="top"
                  >車費 <br />${{
                    sumBy(
                      "cost",
                      (o) =>
                        ["confirmed", "waiting"].indexOf(
                          o.status.toLowerCase()
                        ) >= 0
                    )
                  }}
                </b-th>
                <b-th
                  >收據 <br />${{
                    sumBy(
                      "receipt",
                      (o) =>
                        ["confirmed", "waiting"].indexOf(
                          o.status.toLowerCase()
                        ) >= 0
                    )
                  }}
                </b-th>
                <b-th
                  ><div style="line-height: 1.2;">
                    其他費用<br />
                    <small>(407 + TIPS)</small>
                  </div>
                  ${{
                    sumBy(
                      "cost_407",
                      (o) =>
                        ["confirmed", "waiting"].indexOf(
                          o.status.toLowerCase()
                        ) >= 0
                    )
                  }}
                </b-th>
                <b-th></b-th>
              </b-tr>
            </template>
            <template #cell(datetime)="data">
              <div class="text-nowrap mb-2">
                {{ data.item.date | formatDate("MMM DD, YYYY") }}<br />{{
                  data.item.time
                }}
              </div>

              <OrderModal
                @orderSubmitted="orderUpdated"
                buttonSize="sm"
                buttonVariant="link"
                class="btn-edit"
                :order="data.item"
              >
                編輯訂單

                <b-icon-exclamation-circle-fill
                  v-if="
                    ['confirmed', 'waiting'].indexOf(
                      data.item.status.toLowerCase()
                    ) < 0
                  "
                ></b-icon-exclamation-circle-fill>
              </OrderModal>
            </template>
            <template #cell(locations)="data">
              <div
                class="address-row"
                v-for="(ad, ad_index) in data.item.locations"
                :key="`add-row-${data.index}-${ad_index}`"
              >
                <span class="d-inline-block">{{ ad }}</span>
              </div>
            </template>
            <template #cell(information)="row">
              <OrderMeta :order="row.item" />
            </template>
            <template #cell(customer_passenger)="data">
              <!-- <div class="text-nowrap">
                <span class="cus-pas-label">訂車人</span><br>
                <template v-if="data.item.customer.name">{{data.item.customer.name}}<br></template>{{data.item.customer.phone}}
              </div> -->

              <div
                class="mt-2 text-nowrap"
                v-if="
                  data.item.passenger &&
                    $_.every(data.item.passenger, (op) => !$_.isEmpty(op)) &&
                    data.item.customer_isnot_passenger
                "
              >
                <span class="cus-pas-label">乘客</span><br />

                <template v-if="$_.isArray(data.item.passenger)">
                  <div
                    v-for="(p, p_index) in data.item.passenger"
                    :key="`passenger_list_${data.item.orderId}_${p_index}`"
                  >
                    <template v-if="p.name">{{ p.name }}<br /></template>
                    {{ p.phone }}
                    <template v-if="p.remark"><br />{{ p.remark }}</template>
                  </div>
                </template>
                <template v-else>
                  <template v-if="data.item.passenger.name"
                    >{{ data.item.passenger.name }}<br
                  /></template>
                  {{ data.item.passenger.phone }}
                  <template v-if="data.item.passenger.remark"
                    ><br />{{ data.item.passenger.remark }}</template
                  >
                </template>
              </div>
              <div v-else>---</div>
            </template>
            <template #cell(total)="data">
              ${{ data.item.cost + data.item.receipt }}
            </template>
            <template #cell(cost)="data"> ${{ data.item.cost || 0 }} </template>
            <template #cell(receipt)="data">
              ${{ data.item.receipt }}
            </template>
            <template #head(cost_407)>
              <div class="text-center" style="line-height: 1;">
                其他費用<br />
                <span style="font-size: .8em;">(407 + TIPS)</span>
              </div>
            </template>
            <template #cell(cost_407)="data">
              ${{ data.item.cost_407 }}
            </template>
          </b-table>
        </template>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import algoliasearch from "algoliasearch/lite";
import { mapState } from "vuex";
import $_ from "lodash";
import { ALGOLIA_APP_ID, ALGOLIA_SEARCH_API_ID } from "../constants";

import DateRangeOptions from "@/components/DateRangeOptions.vue";
import CustomerForm from "@/components/CustomerForm.vue";
import SearchInput from "@/components/SearchInput.vue";
import OrderMeta from "@/components/OrderMeta.vue";
import OrderModal from "@/components/OrderModal.vue";

export default {
  name: "CustomersPage",
  components: {
    DateRangeOptions,
    CustomerForm,
    SearchInput,
    OrderMeta,
    OrderModal,
  },
  methods: {
    sumBy(key, condition = (o) => true) {
      return $_.sumBy(this.orderItems, (o) => {
        if (condition(o) && $_.isFinite(o[key])) {
          return o[key];
        } else {
          return 0;
        }
      });
    },
    searchFieldChange(input) {
      this.searchField = input;
    },
    searchPhone() {
      this.searchField = this.stringHelpers.phoneFormat(this.searchField);
      this.$store.dispatch("customers/get", {
        filters: {
          phone: this.searchField,
        },
      });
    },
    showNewCustomer() {
      this.$store.commit("customers/resetCustomer");
      this.showNewCustomerForm = true;
    },
    selectCustomer(customer) {
      this.selectedCustomer = customer;
      this.getCustomerOrder();
    },
    getCustomerOrder(customer = this.selectedCustomer) {
      if (customer) {
        this.showCustomerRecentOrders = true;

        this.$store.dispatch("orders/get", {
          filters: {
            customerId: this.customer.customerId,
            statuses: ["confirmed", "Confirmed"],
            ...this.dateRange,
          },
          stateKey: "recentOrders",
        });
      }
    },
    setCustomer() {
      const _customer = Object.assign({}, this.customer);
      _customer["addresses"] = [
        _customer["homeAddress"],
        _customer["workAddress"],
      ];

      this.$store.dispatch("customers/set", {
        customerId: _customer.customerId,
        data: _customer,
      });
    },
    orderUpdated() {
      this.getCustomerOrder();
    },
  },
  computed: {
    ...mapState({
      orderItems: (state) => state.orders.recentOrders,
      customer: (state) => {
        if ($_.isEmpty(state.customers.singleCustomer)) {
          return null;
        } else {
          const _customer = Object.assign({}, state.customers.singleCustomer);
          Object.assign(_customer, {
            homeAddress: _customer.addresses?.[0],
            workAddress: _customer.addresses?.[1],
          });
          return _customer;
        }
      },
    }),
  },
  watch: {
    dateRange(nv) {
      this.getCustomerOrder();

      let myDateRange = JSON.stringify({
        startDate: nv.startDate.getTime(),
        endDate: nv.endDate.getTime(),
      });
      localStorage.customerDateRange = myDateRange;
    },
  },
  mounted() {
    if (this.$route.query.customerId) {
      this.$store.dispatch("customers/getSingle", this.$route.query.customerId);
    }
  },
  data() {
    const orderFields = [
      {
        key: "dateTime",
        label: "日期",
        stickyColumn: true,
      },
      {
        key: "locations",
        label: "地址",
      },
      {
        key: "information",
        label: "訂單資訊",
      },
      {
        key: "customer_passenger",
        // label: "訂車人 / 乘客",
        label: "乘客",
      },
      {
        key: "total",
        label: "總數",
        tdClass: "text-center",
        thClass: "text-center",
      },
      {
        key: "cost",
        label: "車費",
        tdClass: "text-center",
      },
      {
        key: "receipt",
        label: "收據",
        tdClass: "text-center",
      },
      {
        key: "cost_407",
        label: "其他費用 (407)",
        tdClass: "text-center",
      },
      {
        key: "remark",
        label: "備註",
      },
    ];

    let dateRange = this.dateHelpers.monthDateRange();
    if (localStorage.customerDateRange) {
      dateRange = JSON.parse(localStorage.customerDateRange);
      dateRange = {
        startDate: new Date(dateRange.startDate),
        endDate: new Date(dateRange.endDate),
      };
    }

    return {
      showCustomerRecentOrders: false,

      showNewCustomerForm: false,
      newCustomer: {},
      selectedCustomer: null,
      searchField: "",

      orderFields,
      dateRange,
      userSearch: "",
      searchClient: algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_API_ID),
    };
  },
};
</script>

<style lang="scss" scoped>
#customers-row {
  height: 100%;
  > div {
    height: 100%;
  }
}
#customers-search-col {
  width: 570px;

  > .full-box {
    overflow-y: auto;
  }
}
#customer-order-col {
  width: calc(100vw - 825px);

  .menu-collapsed & {
    width: calc(100vw - 675px);
  }
}

.table-responsive {
  height: calc(100% - 80px);
  max-height: none;
  width: auto;
  overflow-y: auto;
  margin-left: -1rem;

  .btn-link {
    padding: 0;
    text-decoration: underline;
    white-space: nowrap;
  }

  tfoot {
    th {
      position: sticky;
      z-index: 3;
      bottom: 0;
    }
  }
}

.cus-pas-label {
  min-width: 40px;
  display: inline-block;
  font-weight: bold;
}

.btn-edit {
  svg.b-icon.bi {
    font-size: 0.8em;
    vertical-align: 0;
  }
}
</style>
